@import 'styles/variables';
@import 'styles/mixins';

.header {
  position: relative;
  border-bottom: 1px solid $border-grey;
  padding: 15px 0;

  h2 {
    font-size: 18px;
    letter-spacing: -0.1px;
    text-align: center;
  }

  span {
    position: absolute;
    right: 15px;
    top: 50%;
    transition: transform;
    transform: translateY(-50%);
    color: $error;
    font-family: $avenir-medium;

    &:hover {
      cursor: pointer;
    }
  }
}

.container {
  padding: 0 20px 160px;

  .imgContainer {
    margin: -1px 0px -6px -17px;

    & > div {
      height: 74px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .error {
    color: $error;
    font-size: 12px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    h3 {
      font-size: 16px;
      margin-right: 5px;
    }

    span {
      font-weight: 900;
    }

    .input {
      margin-bottom: 0;
      width: 100px;

      input {
        text-align: right;
        color: $main;
      }
    }
  }

  label {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.25;
    opacity: 0.9;
    margin-top: 30px;
  }

  .radioButtons {
    margin-bottom: 20px;

    label {
      margin-top: 5px;
      padding: 8px 5px;
      border: 2px solid $border-grey;
      border-radius: 10px;
      margin-right: 0;

      &:hover {
        cursor: pointer;
        background-color: #e7fbf9;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      &.active {
        border: 2px solid $main;
      }
    }
  }

  .checkBox {
    label {
      margin-top: 15px;
    }
  }

  .summaryBox {
    text-align: center;
    border-radius: 8px;
    padding: 10px 30px;
    margin-top: 20px;
    font-size: 16px;
    backdrop-filter: blur(10px);
    background-color: rgba(163, 119, 254, 0.13);

    p {
      line-height: 1.25;
      margin-top: 7px;
    }

    h3 {
      color: $main;
      font-size: 16px;
    }
  }

  h4 {
    color: $main;
    font-family: $avenir-black;
    letter-spacing: 3.5px;
    opacity: 0.8;
    margin-top: 30px;
  }

  .assets {
    display: flex;
    flex-wrap: wrap;

    .asset {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      background-color: $light-grey;
      border-radius: 10px;
      padding: 5px;

      .uploadPdf {
        display: flex;
        align-items: center;
        margin-left: 5px;

        span {
          margin-left: 10px;
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .iconContainer {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 10px;
      }

      a {
        height: 60px;
      }

      img,
      video {
        border-radius: 10px;
        object-fit: cover;
      }

      .error {
        display: flex;
        margin-top: -5px;
        margin-bottom: 5px;
        color: $error;
        font-size: 12px;
      }

      .videoError {
        display: flex;
        font-size: 12px;
        margin: 0 auto 0 5px;
        color: $error;
      }
    }
  }

  .switchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    label {
      margin: 0;
    }
  }

  .linkContainer {
    display: flex;
    align-items: center;
    padding: 5px 0;
    margin: 10px 0;
    border-top: 1px solid $border-grey;
    border-bottom: 1px solid $border-grey;
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  background-color: $white;
  box-shadow: 0 2px 30px 0 rgba(91, 0, 219, 0.1);
  z-index: 1;

  @include small-desktop {
    width: 50%;
    left: unset;
  }

  @include desktop {
    width: 35%;
    left: unset;
  }

  .price {
    min-width: 100px;
    font-size: 26px;
    font-family: $avenir-black;
    letter-spacing: 0.6px;
    margin-right: 30px;
    text-align: center;
  }

  button {
    min-width: 130px;
  }
}

.alert {
  width: auto !important;
  margin: 55px 15px 0 15px !important;

  h3 {
    font-size: 18px;
  }

  span {
    font-size: 18px;
    margin: 20px 0 12px;
  }
}

.topShadow {
  height: 141px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0));
  position: absolute;
  top: 0;
  z-index: 1;
}

.bottomShadow {
  height: 141px;
  width: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0) 72%);
  position: absolute;
  bottom: 0;
  z-index: 1;
}
