@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.wallet {
  .header {
    width: 100%;
    padding: 70px 15px 20px;

    h3 {
      font-size: 16px;
      line-height: 1.25;
      opacity: 0.9;
      margin: 10px 0 10px;
      text-align: center;
    }

    p {
      text-align: center;
      opacity: 0.8;
      margin: 10px 0;
      font-size: 12px;
    }

    .balanceBox {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light-grey;
      height: 56px;
      border-radius: 28px;
      font-size: 24px;
      font-family: $avenir-heavy;
      margin-bottom: 10px;

      svg {
        position: absolute;
        left: 20px;
      }
    }

    span {
      margin-bottom: 10px;
      color: $error;
      text-align: center;
      width: 100%;
      display: block;
      font-size: 12px;
    }
  }

  .shareButton {
    height: auto;
    display: flex;
    margin: 10px 15px 0 auto;
    justify-content: flex-end;
  }

  .affiliateBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px 15px;
    margin: 30px 15px 20px;
    // Test both bg colors below
    background-color: $bg-grey;
    background-color: #f5f0ff;
    z-index: 2;
    box-shadow: 0 0 25px 0 #c8ccd9;

    h3 {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .identityVerificationLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-grey;
  }

  .paddingBottom {
    padding-bottom: 120px;

    @include small-desktop-and-up {
      padding-bottom: 20px;
    }
  }

  .payoutMethods {
    margin-top: 15px;
    background-color: $white;

    .payoutMethod {
      display: flex;
      flex-direction: column;
      padding: 15px 15px 15px 0;
      margin-left: 15px;
      border-bottom: 1px solid #e7eaf2;
      font-size: 15px;

      .value {
        margin-left: auto;
        text-align: right;
        font-size: 16px;
        font-family: $avenir-heavy;
      }

      .badges {
        margin-left: auto;
        margin-top: 5px;
      }
    }
  }

  .uploadDocument {
    margin: 0 15px;

    .uploadBtn {
      width: 100%;
      height: 40px;
      margin-top: 10px;
    }
  }

  .taxForm {
    margin: 15px 15px 40px;

    .taxFormStatus {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin-bottom: 0;
      }
    }

    h3 {
      margin-bottom: 15px;
      font-size: 16px;
    }

    .taxFormBtn {
      height: auto;
      width: 100%;
      margin-top: 15px;
    }
  }
}

.form {
  padding: 20px 15px 140px;
  background: #fafafa;

  @media screen and (min-width: 601px) and (max-width: 960px) {
    width: 70%;
    margin: 0 auto;
  }

  table {
    background: #fff;
  }
}

.chip {
  height: 22px !important;
  margin-left: 5px;
}

.payoutTitle {
  margin-bottom: 20px;
  text-align: center;
  color: $main;
}

.payoutSubtitle {
  margin-top: -10px;
  text-align: center;
  margin-bottom: 20px;
}

.noPadding {
  padding-left: 0;
  padding-right: 0;
}

.rowSelected {
  background-color: #f5f0ff !important;
}

.rowHover:hover {
  background-color: #f5f0ff !important;
}

.pagination {
  padding-bottom: 100px;

  @include small-desktop-and-up {
    padding-bottom: 30px;
  }
}

.content {
  height: calc(100% - 156px);
  padding: 20px 0 20px 15px;
  background-color: $white;
  border-radius: 10px;

  h4 {
    margin: 30px 0 20px;
    font-family: $avenir-black;
    font-size: 14px;
    color: $main;
    letter-spacing: 3.5px;
    opacity: 0.8;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .payout {
    padding: 15px 0 15px;
    border-bottom: 1px solid #e7eaf2;

    .price {
      font-family: $avenir-heavy;
      font-size: 16px;
      color: $main;
    }
  }

  .transaction {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    position: relative;

    &:last-child {
      .details {
        display: flex;
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    .chip {
      height: 18px !important;
      margin: 10px 15px 10px auto;

      &:hover {
        background-color: rgba(91, 0, 219, 0.2);
      }
    }

    .payoutChip {
      height: 18px;
      margin: 5px 15px 0;

      span {
        min-width: fit-content;
        font-family: $avenir-heavy !important;
        font-size: 12px !important;
        padding: 0 10px;
      }
    }

    .redChip {
      span {
        background-color: $error;
        color: $white;
        border-radius: 16px;
      }
    }

    .purpleChip {
      span {
        background-color: $main;
        color: $white;
        border-radius: 16px;
      }
    }

    .details {
      width: calc(100% - 65px);
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e7eaf2;

      .title {
        display: flex;
        font-size: 15px;
        line-height: 1.33;
        margin-bottom: 5px;
        max-width: calc(100vw - 200px);
        word-break: break-all;
        padding-right: 10px;
      }

      .price {
        display: flex;
        align-items: center;
        margin-bottom: auto;
        color: $main;
        font-size: 16px;
        font-weight: 900;
        font-family: $avenir-heavy;
        min-width: 130px;
        justify-content: flex-end;

        svg {
          width: 14px;
          height: 14px;
          margin: 0 15px 2px 7px;
        }
      }
    }

    .iconContainer {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f0ff;
      border-radius: 50%;
    }
  }

  .date {
    font-family: $avenir-medium-oblique;
    font-size: 13px;
    line-height: 20px;
    color: #9f9ead;
  }
}
