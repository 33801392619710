@import './variables';
@import './fonts';
@import './mixins';
@import './text';

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $avenir-primary;
  background-color: $calcite;
  color: $primary-dark;

  &.logged-in div.crisp-client div#crisp-chatbox a {
    display: none !important;
  }

  &.logged-in:has(#inventory-setup) {
    div.crisp-client div#crisp-chatbox a {
      display: block !important;
    }
  }

  &:has(#profile-links) {
    div.crisp-client div#crisp-chatbox a {
      display: none !important;
    }
  }
}

#__next {
  height: 100%;
}

* {
  caret-color: $main;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-dark;
  font-family: $avenir-heavy;
}

p {
  color: $primary-dark;
}

ul,
li {
  padding: 0;
  margin: 0;
  text-decoration: none;

  &:hover {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}

a {
  &:hover {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    filter: brightness(85%);
  }
}

.col-centered {
  float: none;
  margin: 0 auto;
}

#content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.PhoneInputCountry {
  position: absolute !important;
  top: 13px;
  left: 14px;
  color: $main;

  &:focus {
    color: $main;
  }
}

.color-main {
  color: $main !important;
}

.color-green-main {
  color: $main-green;
}

.cursor-pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.network-field {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 12px;
    opacity: 0.5;
    margin-top: 5px;
    padding: 0 5px;
    text-align: center;

    @include desktop {
      font-size: 14px;
    }

    &.min-width {
      min-width: 95px;
    }
  }

  img {
    min-width: 50px;
  }
}

.hover-icon {
  &:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(150%);
    cursor: pointer;
  }
}

.hover {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.no-hover {
  cursor: default;
  filter: brightness(100%);
  transform: none;
}

.white-exit {
  @include small-desktop-and-up {
    left: 25px;
  }

  @include desktop {
    left: calc((100% - 1140px) / 2);
    padding-left: 0px !important;
  }
}

.MuiDropzoneArea-root {
  height: 96px;
}

.MuiBox-root {
  padding: 15px !important;
}

// Swal

.swal-modal {
  font-family: $avenir-primary;

  .swal-button {
    font-family: $avenir-heavy;
  }

  .swal-button--confirm {
    background-color: $main;
  }
}

.full-width {
  width: 100% !important;
}

.split {
  width: 33.3% !important;
}

.border-bottom {
  border-bottom: 1px solid $border-grey;
}

.border-top {
  border-top: 1px solid $border-grey;
}

.color-red {
  color: $error;
}

.avenir-heavy {
  font-family: $avenir-heavy;
}

.MuiOutlinedInput-adornedStart {
  height: 80px !important;
}

.g-fill-white {
  g {
    fill: white;
  }
}

.circle-fill-grey {
  opacity: 0.5;
}

.circle-heart-img {
  display: flex;
  align-self: center;
  margin-left: auto;
  z-index: 2;

  &:hover {
    cursor: pointer;
  }
}

.profile-badge {
  z-index: 2;

  span {
    font-family: $avenir-heavy;
    font-weight: 900;
    text-shadow: 0 1px 2px $border-grey;
    padding: 0 8px 0 5px;
    margin-left: 5px;
  }
}

.custom-badge {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0 20px;
  border-radius: 25px;
  background-color: $primary-dark;
  border: 1px solid $white;
  color: $white;
  font-family: $avenir-heavy;
}

.custom-badge-active {
  border-color: $main-green;
}

.error-msg {
  display: flex;
  font-size: 12px;
  margin-left: 14px;
  margin-top: -18px;
  color: #f44336;
}

.edit-icon-border {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $white;
  border-radius: 50%;
}

textarea {
  width: 100%;
  border: 1px solid $mid-grey;
  font-family: $avenir-primary;
  font-size: 16px;
  border-radius: 15px;
  padding: 10px 15px;
  outline: none;

  &::placeholder {
    opacity: 0.5;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border: 2px solid $main;
    padding: 9px 14px;
  }
}

.card-name-input-invalid.Mui-error {
  fieldset {
    border-width: 2px;
  }
}

.light-purple-chip {
  &.MuiChip-root {
    height: 18px;
    background-color: rgba(91, 0, 219, 0.1);
    margin-left: auto;

    span {
      min-width: fit-content;
      font-family: $avenir-heavy !important;
      font-size: 12px !important;
      padding: 0 10px;
      color: $main;
      margin-top: 1px;
    }

    img {
      margin-left: 9px;
      filter: invert(89%) sepia(90%) saturate(10000%) hue-rotate(269deg) brightness(75%)
        contrast(101%);
    }
  }
}

.blue-chip {
  &.MuiChip-root {
    height: 18px;
    background-color: #00adef;
    margin-left: auto;

    span {
      min-width: fit-content;
      font-family: $avenir-heavy !important;
      font-size: 12px !important;
      padding: 0 10px;
      color: $white;
      margin-top: 1px;
    }
  }
}

.light-green-chip {
  &.MuiChip-root {
    height: 18px;
    background-color: $pastel-green;

    span {
      min-width: fit-content;
      font-size: 12px;
      font-family: $avenir-primary !important;
      color: $primary-dark;
      padding: 0 10px;
      margin-top: 1px;
    }
  }
}

.light-red-chip {
  &.MuiChip-root {
    height: 18px;
    background-color: $error;
    margin-left: auto;

    span {
      min-width: fit-content;
      font-family: $avenir-heavy !important;
      font-size: 12px !important;
      padding: 0 10px;
      color: $white;
      margin-top: 1px;
    }

    img {
      margin-bottom: 2px;
      margin-left: 9px;
      filter: invert(89%) sepia(90%) saturate(10000%) hue-rotate(269deg) brightness(75%)
        contrast(101%);
    }
  }
}

.red-chip {
  &.MuiChip-root {
    background-color: $error;

    span {
      color: $white;
    }
  }
}

.green-chip {
  &.MuiChip-root {
    background-color: $main-green !important;
    height: 18px !important;

    span {
      color: $main;
      font-family: $avenir-heavy !important;
      font-size: 12px !important;
      padding: 0 10px;
      margin-top: 1px;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.purple-chip {
  &.MuiChip-root {
    background-color: $main;

    span {
      color: $white;
    }
  }
}

span.disabled-chip {
  display: flex;
  align-items: center;
  min-height: 18px;
  font-size: 12px !important;
  padding: 2px 4.3px;
  border-radius: 9px;
  background-color: $primary-dark;
  color: $white;
  font-family: Avenir;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal !important;
  text-align: center;
}

span.green-chip {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 12px !important;
  padding: 2px 4.3px;
  border-radius: 9px;
  background-color: $main-green;
  color: $white;
  font-family: Avenir;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal !important;
  text-align: center;
}

.slider-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  h4 {
    font-size: 16px;
    line-height: 1.25;
    opacity: 0.9;
  }

  span {
    font-size: 16px;
    line-height: 1.25;
    opacity: 0.8;
    color: $main;
    font-family: $avenir-heavy;
  }
}

.MuiSlider-thumb::after {
  display: none;
}

// Video.js

.vjs-big-play-button {
  background-color: transparent !important;
  height: 100px !important;
  background-image: url('/icons/play.svg') !important;
  background-repeat: no-repeat !important;
  transform: translate(-50%, -50%);
  margin: 0 !important;
  border: none !important; // @stylint ignore
  box-shadow: none !important; // @stylint ignore

  &:hover {
    opacity: 0.9 !important;
  }

  .vjs-icon-placeholder {
    display: none !important;
  }
}

.video-js {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100% !important;

  &:hover {
    cursor: pointer;
  }
}

.svg-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(105%) contrast(103%);
}

.vjs_video_3-dimensions.vjs-fluid {
  padding: 0 !important;
}

.style-video {
  border-radius: 15px;
}

.video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  background: transparent;
  border-radius: 15px;
}

.asset-chip {
  &.MuiChip-root {
    position: absolute;
    right: 3px;
    bottom: 5px;
    min-height: 18px;
    height: auto;
  }

  span {
    padding: 0 7px;
    font-family: $avenir-heavy;
    font-size: 12px;
  }
}

.hover-success:hover {
  opacity: 1;
  filter: invert(67%) sepia(59%) saturate(368%) hue-rotate(96deg) brightness(93%) contrast(85%);
  cursor: pointer;
}

.hover-error:hover {
  opacity: 1;
  filter: invert(45%) sepia(99%) saturate(2425%) hue-rotate(330deg) brightness(99%) contrast(113%);
  cursor: pointer;
}

.MuiCircularProgress-colorSecondary {
  color: $white !important;
}

// Date

.SingleDatePickerInput__block {
  background-color: #fafafa !important;
  display: flex !important;
}

.SingleDatePicker_picker {
  z-index: 3 !important;
}

.DateInput__block {
  border-radius: 20px;
}

.DateInput_input__small {
  padding: 9px 7px 9px !important;
  padding-left: 40px !important;
  border-radius: 20px !important;
  background-color: #fafafa !important;
}

.SingleDatePickerInput__withBorder {
  border-radius: 20px !important;
  border: 1px solid $mid-grey !important;

  &:focus-visible {
    border: none !important;
  }
}

.DateInput_input {
  border: 2px solid transparent !important;
}

.DateInput_input__focused {
  border: 2px solid $main !important;
  box-shadow: none !important;
}

.SingleDatePickerInput_calendarIcon {
  position: absolute;
  z-index: 1;
  top: 3px;
  display: flex;
  align-items: center;
  padding-left: 5px !important;
}

.DayPicker_transitionContainer__vertical {
  height: 350px !important;
}

.DateInput_input__focused {
  border: 0;
}

.DayPickerNavigation_button__default:focus,
button:focus {
  outline: none;
}

.CalendarDay__selected {
  background-color: $main !important;
  border: 1px solid $main !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $main !important;
}

// Toastify

.notification-toast {
  div {
    margin: 0;
  }

  .Toastify__close-button {
    display: none;
  }

  &.Toastify__toast {
    padding: 0;
  }
}

.Toastify__toast-container {
  width: fit-content;
  max-width: 400px;
  margin-left: auto;
}

.Toastify__toast-theme--light {
  background: $light-grey;
  color: #2f2d3f;
  border-radius: 10px;
  margin: 15px;
  box-shadow: none;
  font-size: 15px;
  font-family: $avenir-book;
}

.Toastify__close-button--light {
  color: $main;
  opacity: 1;
}

.Toastify__close-button > svg {
  width: 18px;
  height: 18px;
}

.Toastify__progress-bar {
  opacity: 0 !important;
}

.Toastify__toast-icon {
  width: unset;
}

.Toastify__toast--error {
  background: #fce7e7;
}

.error-icon-container {
  background-color: $error !important;
}

.mobile-outlined-button:hover {
  svg {
    fill: $white !important;
  }
}

.fullscreen-loader-container {
  position: fixed;
  top: 0;
  height: 100%;
  right: 0;
  left: 0;
  background-color: $primary-dark;
  text-align: center;
  z-index: 9999;
  overflow: hidden;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: $avenir-black;
    letter-spacing: 3.5px;
    opacity: 0.8;
    color: #e7eaf2;
    margin-top: 20px;
  }
}

.MuiCircularProgress-svg {
  width: 40px;
}

.MuiAlert-standardError {
  .MuiAlert-icon {
    color: rgb(97, 26, 21) !important;
  }
}

.MuiAlert-standardInfo {
  .MuiAlert-icon {
    color: #323643 !important;
    margin-right: 0px;
  }
}

.main-alert {
  background-color: rgba(50, 54, 67, 0.4) !important;
  opacity: 0.5 !important;
  color: $white !important;

  svg {
    fill: $white !important;
  }
}

.info-box {
  width: 100%;
  order: 2;
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 8px;
  color: #323643;
  opacity: 0.8;
  background-color: #ebedf2;
}

#privacy,
#terms {
  display: block;
  padding: 50px 15px 100px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 100px 150px;
  }

  * {
    font-family: 'Exo 2';
  }

  ul {
    margin-left: 50px;
  }
}

.ml-0 {
  margin-left: 0;
}

.fw-n {
  font-weight: normal;
  font-family: 'Avenir Roman';
  font-size: 12px;
}

.smaller-header {
  height: 50px !important;
}

.error-border {
  border: 1px solid #f44336 !important;

  &:focus {
    border: 2px solid #f44336 !important;
  }
}

.icon-btn-hover-dark {
  &.MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
}

.divider {
  position: absolute;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  left: 0;
  right: 0;

  @include small-desktop-and-up {
    left: 220px;
  }
}

// Notification

.notification {
  display: flex;
  align-items: center;
  min-height: 64px;
  border-radius: 10px;
  background-color: $light-grey;
  padding: 8px;
  margin-bottom: 20px;

  h3 {
    font-size: 15px;
  }

  p {
    font-family: $avenir-book;
    font-size: 15px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $main;
  }

  button {
    margin-bottom: auto;
  }
}

.tab-panel {
  .MuiBox-root {
    padding: 0 !important;
  }
}

.green-btn {
  button {
    height: 40px;
    background-color: $main-green;
    color: $primary-dark;

    &:hover {
      background-color: rgba(18, 223, 199, 0.9);
    }
  }
}

.chevron-left {
  position: absolute;
  display: flex;
  top: 10px;
  left: 15px;
  z-index: 2;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
  }

  @include small-desktop-and-up {
    top: 20px;
    left: 40px;
  }

  @media screen and (min-width: 1500px) {
    left: calc((100% - 1140px) / 2);
  }
}

.dark-chip {
  position: absolute;
  height: auto !important;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  span {
    font-size: 13px;
    padding: 0 7px;
  }
}

.checkbox-nested-error {
  label span {
    color: $error;
  }
}

.asset-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .search-icon {
    position: absolute;
    filter: brightness(120%);
  }
}

.larger-input {
  .MuiOutlinedInput-root {
    min-height: 80px;
    height: 80px;
  }
}

.acting-container {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  top: 50px;
  z-index: 10;
  opacity: 1;
  padding: 8px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  font-family: $avenir-medium;
  color: $primary-dark;

  @media screen and (min-width: 700px) {
    padding: 15px;
    font-size: 14px;
  }

  span {
    font-family: $avenir-black;
  }
}

// Color Picker

.react-colorful {
  width: 150px !important;
  height: 150px !important;
}

.react-colorful__pointer {
  width: 22px !important;
  height: 22px !important;
}

.mr-0 {
  margin-right: 0 !important;
}
