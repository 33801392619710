.container {
  min-height: 100vh;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.07);
  padding: 2rem;
  max-width: 600px;
  width: 100%;
}

.title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #d32f2f;
  margin-bottom: 1rem;
}

.message {
  font-size: 1rem;
  color: #333333;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.details {
  background-color: #f4f4f4;
  border-radius: 6px;
  padding: 1rem;
  font-size: 0.875rem;
  color: #444;
  white-space: pre-wrap;
  overflow-x: auto;
  max-height: 300px;
}

.summary {
  cursor: pointer;
  color: #1976d2;
  font-weight: 500;
}

.stacktrace {
  margin-top: 0.75rem;
}
